import { Variants } from 'framer-motion';
import { NOTIFICATION_CONFIG } from '../config';
import { NotificationProps } from '../NotificationItem/index.types';
import { NotificationStackPosition } from './index.types';

/**
 * Get the initial position for notification entry animation
 * @param position - The position of the notification stack (tc, tr, tl, bc, br, bl)
 * @param yOffset - The calculated Y offset for the notification's final position
 * @returns Initial coordinates for the entry animation
 */
const getInitialPosition = (position: NotificationStackPosition) => {
  const DISTANCE = 150;

  switch (position) {
    case 'tc': // top center - enters from top
      return { x: 0, y: -DISTANCE };

    case 'bc': // bottom center - enters from bottom
      return { x: 0, y: DISTANCE };

    case 'tr': // top right - enters from top only, not right
    case 'tl': // top left - enters from top only, not left
      return { x: 0, y: -DISTANCE };

    case 'br': // bottom right - enters from bottom only, not right
    case 'bl': // bottom left - enters from bottom only, not left
      return { x: 0, y: DISTANCE };

    default:
      return { x: 0, y: -DISTANCE };
  }
};

/**
 * Calculates the duration (in seconds) for displaying a notification
 * based on the length of the notification text.
 *
 * @param {string} notificationText - The notification message.
 * @param {boolean} isTruncated - Flag to indicate if message is truncated.
 * @returns {number} - The calculated duration, ensuring a minimum of 3 seconds.
 */
export function calculateNotificationDuration(
  notificationText: string,
  isTruncated: boolean
): number {
  const DEFAULT_DURATION = 3;
  const CHAR_DURATION = 0.07;

  if (!notificationText || isTruncated) {
    return DEFAULT_DURATION;
  }

  const calculatedDuration = Math.floor(
    notificationText.length * CHAR_DURATION
  );

  return Math.max(calculatedDuration, DEFAULT_DURATION);
}

/**
 * Get the animation variants for the notification stack
 */
export const getAnimationVariants = (
  index: number,
  position: NotificationStackPosition,
  isHovered: boolean,
  getOffsetY: (index: number) => number,
  notification: NotificationProps
): Variants => {
  const yOffset = getOffsetY(index);
  const initialPos = getInitialPosition(position);
  const shouldAnimate = index === 0 && !notification.hasAnimated;
  const isMovingToFront = index === 0 && notification.hasAnimated;
  const isStacked = index > 0;

  const springTransition = {
    type: 'spring',
    stiffness: 150,
    damping: 20,
    mass: 1.2,
    restDelta: 0.01,
  };

  return {
    initial: {
      opacity: shouldAnimate ? 0 : 1,
      scale: shouldAnimate ? 0.95 : 1,
      x: shouldAnimate ? initialPos.x : 0,
      y: shouldAnimate ? initialPos.y : yOffset,
    },
    animate: {
      opacity: 1,
      scale: isHovered ? 1 : Math.pow(NOTIFICATION_CONFIG.STACK_SCALE, index),
      x: 0,
      y: yOffset,
      transition: {
        duration: 0.5,
        ease: 'easeOut',
        opacity: {
          duration: 0.2,
          ease: 'easeOut',
        },
        scale: isStacked ? { duration: 0 } : springTransition,
        y: isMovingToFront
          ? { duration: 0 }
          : {
              ...springTransition,
            },
      },
    },
    exit: {
      opacity: 0,
      scale: 0.95,
      y: initialPos.y,
      transition: {
        duration: 0.3,
        ease: 'easeOut',
        opacity: {
          duration: 0.15,
          ease: 'easeOut',
        },
        scale: {
          duration: 0.3,
          ease: 'easeOut',
        },
      },
    },
  };
};

/**
 * Calculate the notification offset Y position
 */
export const calculateNotificationOffsetY = (
  index: number,
  isHovered: boolean,
  visibleNotifications: NotificationProps[],
  heights: Record<string, number>,
  gap: number
): number => {
  if (isHovered) {
    let offset = 0;
    for (let i = 0; i < index; i++) {
      const prevNotification = visibleNotifications[i];
      offset += (heights[prevNotification.id] || 0) + gap;
    }
    return offset;
  }

  const frontHeight = heights[visibleNotifications[0]?.id || ''] || 0;
  return index === 0
    ? 0
    : frontHeight -
        (heights[visibleNotifications[index]?.id || ''] || 0) +
        index * NOTIFICATION_CONFIG.STACK_OFFSET;
};

/**
 * Calculate the total height of the notification stack
 */
export const calculateTotalHeight = (
  isHovered: boolean,
  visibleNotifications: NotificationProps[],
  heights: Record<string, number>,
  gap: number
): number => {
  if (isHovered) {
    return visibleNotifications.reduce((total, notification, index) => {
      const height = heights[notification.id] || 0;
      return total + height + (index > 0 ? gap : 0);
    }, 0);
  }

  const frontHeight = heights[visibleNotifications[0]?.id || ''] || 0;
  return (
    frontHeight +
    (visibleNotifications.length - 1) * NOTIFICATION_CONFIG.STACK_OFFSET
  );
};

/**
 * Calculate the container width based on screen size
 */
export const calculateContainerWidth = (): number => {
  const screenWidth = window.innerWidth;
  const maxWidth = screenWidth - NOTIFICATION_CONFIG.SCREEN_PADDING * 2;
  return Math.min(NOTIFICATION_CONFIG.NOTIFICATION_WIDTH, maxWidth);
};

/**
 * Create a debounced hover handler
 */
export const createHoverHandler = (
  callback: (value: boolean) => void,
  timeout: number
) => {
  let timeoutId: ReturnType<typeof setTimeout>;

  return (isEntering: boolean) => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      callback(isEntering);
    }, timeout);
  };
};

/**
 * Update notification heights
 */
export const updateNotificationHeights = (
  heightRefs: Record<string, HTMLDivElement | null>
): Record<string, number> => {
  const newHeights: Record<string, number> = {};
  Object.entries(heightRefs).forEach(([id, ref]) => {
    if (ref) {
      // Store original height
      const originalHeight = ref.style.height;

      // Measure natural height
      ref.style.height = 'auto';
      newHeights[id] = ref.offsetHeight;

      // Restore original height
      ref.style.height = originalHeight;
    }
  });

  return newHeights;
};
