import { CallLogListItem } from 'Components/CallLogs/CallLogListItem';
import {
  CALL_TYPES_CONTENT,
  CALL_LOGS_PER_PAGE,
} from 'Components/CallLogs/constants';
import { OfflineBanner } from 'Components/CallLogs/OfflineBanner';
import LazyLoader from 'Components/LazyLoader';
import { ContentArea } from 'Components/shared/ContentArea';
import { FilterBox } from 'Components/shared/FilterBox';
import { Heading } from 'Components/shared/Heading';
import { TESTID_CALL_LOGS } from 'Constants/testids';
import { debounce } from 'lodash';
import { MobXProviderContext, observer } from 'mobx-react';
import { useCallLogs } from 'Modules/callLogs/index.request';
import React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Grid, List } from 'semantic-ui-react';
import { RootStoreProps } from 'Stores/RootStore.types';
import { formatDateGroup } from 'Utils/formatDateGroup';
import { CallLogCategoryType } from '../types';
interface ICallsDirectoryListProps {
  typeOfCallId: CallLogCategoryType;
}
export const CallsDirectoryList: React.FC<ICallsDirectoryListProps> = observer(
  ({ typeOfCallId }) => {
    const [callPageNum, setCallPageNum] = React.useState(1);
    const {
      callLogsStore: { searchQuery, setSearchQuery },
    } = React.useContext<RootStoreProps>(MobXProviderContext);

    const {
      data: callLogs,
      fetchNextPage,
      isLoading,
    } = useCallLogs({
      typeOfCallId,
      query: searchQuery,
    });

    const allLogs = React.useMemo(
      () => callLogs?.pages.flatMap((page) => page) ?? [],
      [callLogs]
    );

    React.useEffect(() => {
      const scrollableList = document.getElementById('directory-list');
      scrollableList?.addEventListener('scroll', () => {
        if (scrollableList?.scrollTop === 0)
          scrollableList?.scrollTo({ top: 1 });
      });

      setSearchQuery('');
      scrollableList?.scrollTo({ top: 1 });

      setCallPageNum(1);

      return () => {
        const scrollableList = document.getElementById('directory-list');
        scrollableList?.removeEventListener('scroll', () => {
          if (scrollableList?.scrollTop === 0)
            scrollableList.scrollTo({ top: 1 });
        });
      };
    }, [typeOfCallId]);

    const filter = debounce((query: string) => {
      setSearchQuery(query);
    }, 500);

    const handleLoadCallLogs = () => {
      setCallPageNum(callPageNum + 1);
      if (!searchQuery?.trim()) {
        void fetchNextPage();
      }
    };

    const displayedSeparators = new Set<string>();

    return (
      <ContentArea id="call-logs" inlineTopBanner={<OfflineBanner />}>
        <Heading variant="h2" className="header-logs">
          {CALL_TYPES_CONTENT[typeOfCallId].title}
        </Heading>
        <FilterBox
          placeholder={CALL_TYPES_CONTENT[typeOfCallId].placeholder}
          sendInput={filter}
          loading={isLoading}
          setPageNum={setCallPageNum}
          testid={TESTID_CALL_LOGS['DIRECTORY_LIST']}
        />
        {allLogs?.length ? (
          <InfiniteScroll
            initialScrollY={1}
            dataLength={callPageNum * CALL_LOGS_PER_PAGE} //This is important field to render the next data
            next={handleLoadCallLogs}
            hasMore
            loader={<></>}
            endMessage={
              <p style={{ textAlign: 'center' }}>
                <b>You have seen it all</b>
              </p>
            }
            // below props only if you need pull down functionality
            refreshFunction={handleLoadCallLogs}
            pullDownToRefresh
            pullDownToRefreshThreshold={4}
            scrollableTarget="directory-list"
          >
            <List
              id="directory-list"
              className="overflow-scroll-y-hidden"
              inverted
              verticalAlign="middle"
              size="large"
              selection
            >
              {allLogs.map((callLog) => {
                const dateGroup = formatDateGroup(callLog.startedAt);
                const isSeparatorNew = !displayedSeparators.has(dateGroup);
                if (isSeparatorNew) displayedSeparators.add(dateGroup);
                return (
                  <>
                    {isSeparatorNew && dateGroup !== 'Today' && (
                      <div
                        key={`separator-${dateGroup}`}
                        className="call-log-separator"
                      >
                        {dateGroup}
                      </div>
                    )}
                    <Grid
                      columns={4}
                      key={`${callLog.id}-${callLog.direction}`}
                      className="call-log-item"
                    >
                      <CallLogListItem {...callLog} />
                    </Grid>
                  </>
                );
              })}
            </List>
          </InfiniteScroll>
        ) : (
          <div className="no-calls-info">
            {isLoading ? (
              <LazyLoader />
            ) : searchQuery.trim().length > 2 ? (
              <>
                <div>Oh no! We couldn't find what you were looking for.</div>
                <div>Check your spelling or try searching for other terms.</div>
              </>
            ) : (
              CALL_TYPES_CONTENT[typeOfCallId].emptySearchText
            )}
          </div>
        )}
      </ContentArea>
    );
  }
);
