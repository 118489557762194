import CloseButton from 'Components/CloseButton';
import EntityProfileObserver from 'Components/EntityProfile';
import LazyLoader from 'Components/LazyLoader';
import { MobXProviderContext, observer } from 'mobx-react';
import React, { useEffect } from 'react';
import { RootStoreProps } from 'Stores/RootStore.types';
import withRouter from '../../hocs/WithRouter';
import { useEntityDetails } from './index.hooks';
import { Styled } from './index.styles';

const EntityProfilePanel = ({ location, navigate }) => {
  const [entityDetails, setEntityDetails] = React.useState(null);

  const { person, ui } = React.useContext(
    MobXProviderContext
  ) as RootStoreProps;

  const { getDetails } = useEntityDetails();

  const closePanel = () => {
    person.setShowPersonDetails(null, null);
    ui.removeFromOpenedRightSidebarsOrder('sidebar-info');
  };

  useEffect(() => {
    const loadEntityDetails = async () => {
      setEntityDetails(await getDetails());
    };

    if (person.showPersonDetails.id && person.showPersonDetails.type) {
      void loadEntityDetails();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [person.showPersonDetails]);

  if (
    ui.loadingConversationForSidebarInfo ||
    !(
      entityDetails?.person ||
      entityDetails?.contact ||
      entityDetails?.extrContacts
    )
  ) {
    return (
      <Styled.Panel
        $zIndex={ui.openedRightSidebarsOrder.get('sidebar-info')}
        $visibility={person.showPersonDetails.id ? 'visible' : 'hidden'}
      >
        <LazyLoader />
      </Styled.Panel>
    );
  }

  return (
    <Styled.Panel
      $zIndex={ui.openedRightSidebarsOrder.get('sidebar-info')}
      $visibility={person.showPersonDetails.id ? 'visible' : 'hidden'}
    >
      <CloseButton onClose={closePanel} pathName={location.pathname} />

      <EntityProfileObserver
        person={entityDetails?.person}
        contact={entityDetails?.contact}
        extrContacts={entityDetails?.extrContacts}
      />
    </Styled.Panel>
  );
};

export default withRouter(observer(EntityProfilePanel));
