import { EventEmitter } from 'events';
import moment from 'moment';
import {
  isInboundAudioReport,
  isOutboundAudioReport,
  isCandidatePairReport,
  isCodecReport,
} from './guards';
import {
  DEFAULT_GET_STATS_INTERVAL,
  MAX_METRICS_PER_CALL,
} from './index.constants';
import {
  RTCStatsReportType,
  ExtendedSession,
  WebRTCStatsOptions,
  CollectedStats,
  CallStats,
  VoipMonitorEvent,
  CodecInfo,
} from './index.types';
import { calculateMosScore } from './utils';
import { VoipAlertAdapter } from './voipAlertAdapter';

/**
 * VoipMonitorService is a service for collecting and analyzing call metrics
 * @class VoipMonitorService
 * @extends EventEmitter
 */
export class VoipMonitorService extends EventEmitter {
  private static instance: VoipMonitorService;
  private peerConnection: RTCPeerConnection | null = null;
  private intervalId: ReturnType<typeof setInterval> | null = null;
  private metricsHistory: CollectedStats[] = [];
  private callId = '';
  private personId = '';
  private options: WebRTCStatsOptions;

  private constructor(options: WebRTCStatsOptions = {}) {
    super();
    this.options = {
      getStatsInterval: DEFAULT_GET_STATS_INTERVAL,
      maxMetricsPerCall: MAX_METRICS_PER_CALL,
      sendFullCallStats: false,
      enableAlertAdapter: false,
      ...options,
    };
  }

  /**
   * Get the instance of the VoipMonitorService
   * @param options - The options
   * @returns The instance of the VoipMonitorService
   */
  public static getInstance(options?: WebRTCStatsOptions): VoipMonitorService {
    if (!VoipMonitorService.instance) {
      VoipMonitorService.instance = new VoipMonitorService(options);
    } else if (options) {
      VoipMonitorService.instance.updateOptions(options);
    }
    return VoipMonitorService.instance;
  }

  /**
   * Update the options
   * @param options - The options
   */
  public updateOptions(options: WebRTCStatsOptions): void {
    this.options = { ...this.options, ...options };
  }

  /**
   * Start monitoring the call
   * @param personId - The person id
   * @param callId - The call id
   * @param session - The session
   */
  public startMonitoring(
    personId: string,
    callId: string,
    session: ExtendedSession
  ): void {
    this.stopMonitoring();

    if (!session) {
      this.emit(VoipMonitorEvent.ERROR, new Error('No session provided'));
      return;
    }

    this.peerConnection =
      session?.sessionDescriptionHandler?.peerConnection || null;
    this.personId = personId;
    this.callId = callId;

    this.metricsHistory = [];

    if (!this.peerConnection) {
      this.emit(
        VoipMonitorEvent.ERROR,
        new Error('No peer connection available')
      );
      return;
    }

    this.peerConnection.addEventListener(
      'connectionstatechange',
      this.handleConnectionStateChange
    );

    this.startCollection();
  }

  /**
   * Handle the connection state change
   */
  private handleConnectionStateChange = (): void => {
    if (!this.peerConnection) return;

    switch (this.peerConnection.connectionState) {
      case 'closed':
      case 'failed':
      case 'disconnected':
        this.stopMonitoring();
        break;
    }
  };

  /**
   * Start the collection
   */
  private startCollection(): void {
    if (!this.peerConnection) {
      this.emit(
        VoipMonitorEvent.ERROR,
        new Error('No peer connection available')
      );
      return;
    }

    this.emit(VoipMonitorEvent.MONITORING_STARTED, this.callId);

    this.intervalId = setInterval(
      () => void this.collectStats(),
      this.options.getStatsInterval
    );
  }

  /**
   * Collect the stats
   */
  private async collectStats(): Promise<void> {
    if (!this.peerConnection) return;

    try {
      const statsReport = await this.peerConnection.getStats();
      const collectedStats: CollectedStats = {
        time: moment.utc().format(),
        raw: [],
      };

      statsReport.forEach((report: RTCStatsReportType) => {
        collectedStats.raw.push(report);

        if (isInboundAudioReport(report)) {
          collectedStats.inboundAudio = report;
        } else if (isOutboundAudioReport(report)) {
          collectedStats.outboundAudio = report;
        } else if (isCandidatePairReport(report)) {
          collectedStats.candidatePair = report;
        }
      });

      this.metricsHistory.push(collectedStats);
      if (this.metricsHistory.length > this.options.maxMetricsPerCall) {
        this.metricsHistory.shift();
      }

      if (this.options.enableAlertAdapter) {
        // Perform alert checks if `enableAlertAdapter` is enabled
        await VoipAlertAdapter.checkStats(
          this.personId,
          this.callId,
          collectedStats
        );
      }
    } catch (error) {
      this.emit(VoipMonitorEvent.ERROR, error as Error);
    }
  }

  /**
   * Stop monitoring the call
   */
  public stopMonitoring(): void {
    if (this.intervalId) {
      const metrics = this.getMetricsHistory();
      const mosScore = calculateMosScore(metrics);

      // Get the first and last metrics for additional data
      const firstMetric = metrics.length > 0 ? metrics[0] : null;
      const lastMetric =
        metrics.length > 0 ? metrics[metrics.length - 1] : null;

      // Extract codec, IP information if available - prefer data from last metric when available
      const codec = this.extractCodecInfo(lastMetric || firstMetric);
      // Calculate summary statistics
      const summary = this.calculateSummaryStats(metrics, mosScore);

      const callStats: CallStats = {
        metadata: {
          personId: this.personId,
          callId: this.callId,
          startTime: firstMetric?.time,
          endTime: moment.utc().format().toString(),
          duration: firstMetric
            ? moment.utc().diff(moment(firstMetric.time), 'seconds')
            : 0,
          codec,
        },
        summary,
        ...(this.options.sendFullCallStats ? { stats: metrics } : {}),
      };

      this.emit(VoipMonitorEvent.MONITORING_ENDED, this.callId, callStats);

      this.cleanUp();
    }
  }

  /**
   * Extract codec information from the metrics
   * @param metric - The first metric
   * @returns The codec information
   */
  private extractCodecInfo(
    stats: CollectedStats | null
  ): CodecInfo | undefined {
    if (!stats?.raw) return undefined;

    const codecEntry = stats.raw.find(isCodecReport);

    if (!codecEntry) return undefined;

    return {
      codecId: codecEntry.id,
      mimeType: codecEntry.mimeType,
    };
  }

  /**
   * Calculate summary statistics from metrics
   * @param metrics - The metrics history
   * @param mosScore - The calculated MOS score
   * @returns Summary statistics
   */
  private calculateSummaryStats(metrics: CollectedStats[], mosScore: number) {
    let totalJitter = 0;
    let totalPacketsLost = 0;
    let totalRtt = 0;
    let rttCount = 0;
    let availableBitrate = 0;

    metrics.forEach((metric) => {
      if (metric.inboundAudio) {
        totalJitter += metric.inboundAudio.jitter || 0;
        totalPacketsLost += metric.inboundAudio.packetsLost || 0;
      }

      if (metric.candidatePair) {
        if (metric.candidatePair.currentRoundTripTime) {
          totalRtt += metric.candidatePair.currentRoundTripTime;
          rttCount++;
        }

        // Use the last available bitrate value
        if (metric.candidatePair.availableOutgoingBitrate) {
          availableBitrate = metric.candidatePair.availableOutgoingBitrate;
        }
      }
    });

    return {
      mosScore,
      averageJitter: metrics.length > 0 ? totalJitter / metrics.length : 0,
      totalPacketsLost,
      averageRoundTripTime: rttCount > 0 ? totalRtt / rttCount : 0,
      availableBitrate,
    };
  }

  /**
   * Get the metrics history
   * @returns The metrics history
   */
  public getMetricsHistory(): CollectedStats[] {
    return [...this.metricsHistory];
  }

  /**
   * Get the latest metrics
   * @returns The latest metrics
   */
  public getLatestMetrics(): CollectedStats | null {
    return this.metricsHistory.length > 0
      ? this.metricsHistory[this.metricsHistory.length - 1]
      : null;
  }

  /**
   * Collect the stats now
   * @returns The latest metrics
   */
  public async collectStatsNow(): Promise<CollectedStats | null> {
    await this.collectStats();
    return this.getLatestMetrics();
  }

  /**
   * Clean up
   */
  public cleanUp(): void {
    this.removeAllListeners();
    if (this.intervalId) {
      clearInterval(this.intervalId);
      this.intervalId = null;
    }

    if (this.peerConnection) {
      this.peerConnection.removeEventListener(
        'connectionstatechange',
        this.handleConnectionStateChange
      );
      this.peerConnection = null;
    }

    this.metricsHistory = [];
    this.personId = '';
    this.callId = '';
  }
}

export default VoipMonitorService.getInstance();
