import * as React from 'react';
import { Loader } from 'semantic-ui-react';
import { LOADER_DELAY } from './index.constants';
import { LazyLoaderProps } from './index.types';

/**
 * A loader component that only appears after a specified delay
 * to prevent flickering for fast operations.
 */
const LazyLoader: React.FC<LazyLoaderProps> = ({
  isLoading = true,
  delay = LOADER_DELAY,
  children,
  ...loaderProps
}) => {
  const [showLoader, setShowLoader] = React.useState(false);
  const timerRef = React.useRef<ReturnType<typeof setTimeout> | null>(null);

  React.useEffect(() => {
    // When loading starts
    if (isLoading) {
      // Set a new timer to show the loader after delay
      timerRef.current = setTimeout(() => {
        setShowLoader(true);
      }, delay);
    } else {
      setShowLoader(false);
    }

    // Cleanup on unmount
    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
        timerRef.current = null;
      }
    };
  }, [isLoading, delay]);

  // Only render the loader if we should show it and we're still loading
  if (isLoading && showLoader) {
    return <Loader active indeterminate {...loaderProps} />;
  }

  // Otherwise render children or null
  return <>{children}</>;
};

export default LazyLoader;
