import { DirectoryListItem } from 'Components/DirectoryPanel/DirectoryListItem';
import LazyLoader from 'Components/LazyLoader';
import { ContentArea } from 'Components/shared/ContentArea';
import { FilterBox } from 'Components/shared/FilterBox';
import { InfoMessageBanner } from 'Components/shared/InfoMessageBanner';
import { debounce } from 'lodash';
import { MobXProviderContext, observer } from 'mobx-react';
import * as React from 'react';
import type { RootStoreProps } from 'Stores/RootStore.types';
import { bugsnagClient } from 'Utils/logUtils';
import { Styled } from './index.styles';

export const Directory: React.FC = observer(() => {
  const {
    search: { getDirectorySearch, directoryMap },
  } = React.useContext<RootStoreProps>(MobXProviderContext);

  const [searchValue, setSearchValue] = React.useState('');
  const [maxContacts, setMaxContacts] = React.useState(0);
  const [directoryPageNumber, setDirectoryPageNumber] = React.useState(1);
  const [hasMore, setHasMore] = React.useState(true);
  const testid = 'directory';
  const directory = directoryMap?.['DIRECTORY'];

  React.useEffect(() => {
    const loadContacts = async () => {
      const directoryContacts = await getDirectorySearch('DIRECTORY');
      const hits = directoryContacts?.data?.hits;
      if (hits) {
        setMaxContacts(hits);
        setDirectoryPageNumber(1);
      }
    };

    loadContacts().catch((err) =>
      bugsnagClient.notify(err, (event) => {
        event.severity = 'error';
        event.context = 'Directory';
        event.addMetadata('custom', {
          function: 'loadContacts',
        });
      })
    );
  }, [getDirectorySearch]);

  const filter = debounce(async (q: string) => {
    setSearchValue(q);
    setHasMore(true);
    setDirectoryPageNumber(1);
    const directoryContacts = await getDirectorySearch('DIRECTORY', q);
    const hits = directoryContacts?.data?.hits;
    if (hits) setMaxContacts(hits);
  }, 500);

  const handleLoadMoreContacts = debounce(async () => {
    if (maxContacts === directory.data.results.length) {
      setHasMore(false);
      return;
    }

    const directoryContacts = await getDirectorySearch(
      'DIRECTORY',
      searchValue,
      directoryPageNumber + 1,
      true
    );

    if (directoryContacts) setDirectoryPageNumber((prevState) => prevState + 1);
  }, 500);

  return (
    <ContentArea>
      <FilterBox
        placeholder="Search Directory"
        sendInput={filter}
        loading={directoryMap['DIRECTORY'].loading}
        testid={`${testid}-filterBoxDirectory`}
      />
      {directory.loading ? (
        <LazyLoader />
      ) : (
        <Styled.InfiniteScroll
          dataLength={Math.min(directoryPageNumber * 20, maxContacts)} //This is important field to render the next data
          next={handleLoadMoreContacts}
          hasMore={hasMore}
          loader={<React.Fragment />}
          scrollableTarget="directory-list"
        >
          <Styled.List id="directory-list">
            {directory?.data?.results?.map(({ source: { id } }) => (
              <DirectoryListItem
                key={id}
                searchItemId={Number(id)}
                {...{ testid }}
              />
            ))}
            <InfoMessageBanner
              testid={`${testid}-bannerInfoNoResultsFound`}
              show={directory?.query && !directory?.data?.hits}
              content={`No results found for "${directory?.query}"`}
            />
          </Styled.List>
        </Styled.InfiniteScroll>
      )}
    </ContentArea>
  );
});
