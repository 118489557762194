export enum VoipMonitorType {
  HIGH_PACKET_LOSS = 'HIGH_PACKET_LOSS',
  HIGH_JITTER = 'HIGH_JITTER',
  HIGH_LATENCY = 'HIGH_LATENCY',
  LOW_MOS = 'LOW_MOS',
}

export interface VoipAlert {
  personId: string;
  callId: string;
  timestamp: string;
  type: VoipMonitorType;
  value: number;
  threshold: number;
}
