import { Banner } from 'Components/shared/Banner';
import { useNotificationStore } from 'Modules/notification/store';
import React, { useCallback, useEffect, useState } from 'react';
import { calculateNotificationDuration } from '../NotificationStack/utils';
import { NotificationItemProps } from './index.types';

/**
 * Notification is a component that displays a banner.
 * @param NotificationProps
 */
export const Notification = ({
  notification,
  position = 'tr',
  isStacked = false,
  isHovered = false,
}: NotificationItemProps): React.ReactElement => {
  const removeNotify = useNotificationStore((state) => state.removeNotify);
  const notificationDurationInSeconds = calculateNotificationDuration(
    notification.message as string,
    notification.truncateBody
  );

  const [remainingTime, setRemainingTime] = useState<number>(
    notificationDurationInSeconds
  );

  const handleClose = useCallback(() => {
    if (notification?.id) {
      removeNotify(position, notification.id);
    }
  }, [notification, position, removeNotify]);

  useEffect(() => {
    /**
     * If the notification is not hovered,
     * and has an autoDismiss,
     * then set a timeout to close the notification
     */
    if (!isStacked && !isHovered && notification?.autoDismiss) {
      const timer = setTimeout(() => {
        if (remainingTime <= 0) {
          handleClose();
        } else {
          setRemainingTime((prev) => prev - 1);
        }
      }, 1000);

      return () => clearTimeout(timer);
    }
  }, [notification, handleClose, isHovered, remainingTime, isStacked]);

  if (!notification) {
    return null;
  }

  const showCloseButton =
    notification.dismissible === 'button' ||
    notification.dismissible === 'both' ||
    notification.dismissible === true;

  return (
    <Banner
      id={notification.id?.toString() || ''}
      type={notification.type || 'info'}
      title={notification.title || ''}
      message={notification.message || ''}
      action={notification.action}
      truncate={notification.truncateBody}
      showCloseButton={showCloseButton}
      isStacked={isStacked}
      onClose={handleClose}
    >
      {notification.children}
    </Banner>
  );
};
