import dotsIcon from 'Assets/images/three-dots-vertical.svg';
import cx from 'classnames';
import ActivityItemPresenceDisplayObserver from 'Components/ActivityItemPresenceDisplay';
import { ChatListItemTextRenderer } from 'Components/ChatList/ChatListItemText/ChatListItemTextRenderer';
import LazyLoader from 'Components/LazyLoader';
import {
  STORE_CONFIG,
  STORE_PARTICIPANT,
  STORE_PHONE_CALL,
  STORE_UI,
} from 'Constants/stores';
import withRouter from 'Hocs/WithRouter';
import { AxiosResponseT, ResultsCollection } from 'Interfaces/axiosResponse';
import { get, isEmpty } from 'lodash';
import { action, observable, makeObservable, toJS } from 'mobx';
import { inject, observer } from 'mobx-react';
import { IPromiseBasedObservable } from 'mobx-utils';
import * as React from 'react';
import { NavLink } from 'react-router';
import { Button, List, Message, Modal, Popup } from 'semantic-ui-react';
import { isNullOrUndefined } from 'util';
import { getCurrentConversationId } from 'Utils/getCurrentConversationId';
import { selectProperVideoTooltipMessageForGroups } from 'Utils/videoConferenceUtils';
import { ActivityItemPhoneVideoButtons } from '../../components/ActivityItemPhoneVideoButtons';
import { ParticipantModel, PersonModel } from '../../models';

import { formatNumberNoPlusIfUS } from '../../utils/phoneUtil';
import { ChatDetailsButton } from '../ActivityItemPhoneVideoButtons/ChatDetailsButton';
import MoreActivityButton from '../MoreActivityButtons';
import { IconButton } from '../shared/IconButton';
import { ActivityListItemProps } from './interface';

/**
 * **This named class is not decorated with `@observer`.** Use the default export from this module if you need the decorated class.
 * @export
 * @class ActivityListItem
 */
const testid = 'activityListitem';

export class ActivityListItem extends React.Component<
  ActivityListItemProps,
  { showConfirmationModal: boolean }
> {
  button;
  constructor(props: ActivityListItemProps) {
    super(props);
    makeObservable(this);
    this.setConversationTopic(props.conversation?.topic);
    this.state = {
      showConfirmationModal: false,
    };
    this.button = React.createRef();
  }

  @observable
  hovered = false;

  @action
  setHovered = (hovered: boolean) => (this.hovered = hovered);

  @observable
  clickedMoreOptionsId = '';

  @action
  setClickedMoreOptionsId = (clickedId: string) =>
    (this.clickedMoreOptionsId = clickedId);

  componentDidUpdate() {
    if (this.button.current) {
      this.button.current.focus();
    }
  }

  detailsClick = (e) => {
    const {
      conversation,
      participantsPbo,
      loggedInPersonId,
      personStore,
      conversationStore,
      ui,
      from,
      handleDetails,
      currentPersonId,
    } = this.props;
    e.preventDefault();
    ui.setOpenedRightSidebarsOrder('sidebar-info');
    if (from === 'group-info') {
      handleDetails(
        currentPersonId,
        typeof currentPersonId === 'number' ? 'person' : 'contact',
        'personInfo'
      );

      return;
    } else {
      if (
        conversation &&
        (conversation?.grouping === 'Channel' ||
          conversation?.grouping === 'Group')
      ) {
        conversationStore.setChannelInfoDetails(conversation?.id, 'group-info');
        personStore.setShowPersonDetails(null, 'person');
      } else if (conversation && conversation?.grouping === 'OneOnOne') {
        if (participantsPbo !== null) {
          participantsPbo.then((ptcResp) => {
            const ptcDataResults: ParticipantModel[] = get(
              ptcResp,
              'data.results',
              []
            );
            const otherConversationParticipants = ptcDataResults.filter(
              (p) => get(p, 'personId', 0) !== loggedInPersonId
            );
            const mySelf = ptcDataResults.find(
              (p) => get(p, 'personId', 0) === loggedInPersonId
            );
            if (
              !isNullOrUndefined(otherConversationParticipants) &&
              otherConversationParticipants.length === 1
            ) {
              const otherPersonId = get(
                otherConversationParticipants[0],
                'personId',
                0
              );
              if (otherPersonId !== 0) {
                personStore.setShowPersonDetails(otherPersonId, 'person');
                conversationStore.setChannelInfoDetails(
                  conversation?.id,
                  'new'
                );
              } else {
                const otherPersonPhone = get(
                  otherConversationParticipants[0],
                  'phone',
                  null
                );
                const extrContact =
                  otherPersonPhone &&
                  personStore.getExtrContactByPhoneNumber(otherPersonPhone);
                if (extrContact) {
                  personStore.setShowPersonDetails(
                    otherPersonPhone,
                    'extrContact'
                  );
                  conversationStore.setChannelInfoDetails('0', 'new');
                } else {
                  personStore.setShowPersonDetails(
                    otherPersonPhone,
                    'unkownContact'
                  );
                  conversationStore.setChannelInfoDetails('0', 'new');
                }
              }
            } else {
              const mySelfPersonId = get(mySelf, 'personId', 0);
              personStore.setShowPersonDetails(mySelfPersonId, 'person');
              conversationStore.setChannelInfoDetails('0', 'new');
            }
          });
        } else {
          throw new Error(
            'Failed to retrieve Participants for Conversation ' +
              conversation?.id
          );
        }
      }
    }
  };

  toggleMuteStatus = (conversationid: string): boolean => {
    const { ui } = this.props;
    const value = ui.selectIfConvMuted(conversationid);
    ui.setConvMuted(conversationid, !value);
    ui.setMoreActionOptions({ show: false, conversationId: '' });
    return !value;
  };

  placeVideoCallConference = () => {
    const { postConferenceByConversationId, conversation, currentPersonId } =
      this.props;
    if (!currentPersonId) {
      postConferenceByConversationId(conversation?.id);
      return;
    }

    this.props.conversationStore
      .loadOrCreateConversationWithPost(currentPersonId)
      .then((resp) => {
        postConferenceByConversationId(resp.data.id?.toString());
      });
  };

  @action
  onMouseOver = () => this.setHovered(true);

  @action
  onMouseLeave = () => this.setHovered(false);

  @observable
  conversationTopic: string;

  @action
  setConversationTopic = (conversationTopic: string) =>
    (this.conversationTopic = conversationTopic);

  handleArchiveConversation = (conversationId: string) => () => {
    const { ui } = this.props;
    this.props.conversationStore
      .removeFromRecentHistList(conversationId)
      .then(() => {
        ui.setMoreActionOptions({ show: false, conversationId: '' });
      });
  };

  openMoreOptions = (conversationId: string) => (e) => {
    const { ui } = this.props;
    e.preventDefault();
    e.stopPropagation();
    ui.setMoreActionOptions({ conversationId: conversationId, show: true });
  };

  toggleConfirmationModal = (e) => {
    const { ui } = this.props;
    e.stopPropagation();
    ui.setMoreActionOptions({ show: false, conversationId: '' });
    this.setState({ showConfirmationModal: !this.state.showConfirmationModal });
  };

  clearScrollToMessageId = (e) => {
    if (
      Number.isFinite(
        this.props.ui.openedRightSidebarsOrder.get('sidebar-info')
      )
    ) {
      this.props.personStore.setShowPersonDetails(null, null);
      this.detailsClick(e);
    }
    this.props.ui.setMessageIdToScroll('');
    const { conversation, navigate, linkSuffix, ui, conversationStore } =
      this.props;
    conversationStore.setPreviousConversation(getCurrentConversationId());
    navigate(
      conversation?.id
        ? `/chat/conversations/${conversation.id}/${linkSuffix || 'chat'}`
        : '/chat'
    );

    ui.setMessageIdToScroll('');
  };

  render() {
    const buttonSize = 'small';

    const {
      config,
      conversation,
      conversationStore,
      conversationId,
      isOnline,
      linkSuffix,
      loggedInAccountId,
      loggedInPersonId,
      loggedInUserActiveConferenceConversation,
      makeCall,
      participant,
      participantsPbo,
      phoneCall,
      postConferenceByConversationId,
      selectParticipantPersonInfo,
      selectPersonPresenceStatus,
      selectUnreadCounts,
      setConversationAndTotalUnreadCount,
      updateMyLastReadMessage,
      ui,
      from,
      setShowPersonDetails,
      currentPersonId,
      personStore: {
        getExtrContactByPhoneNumber,
        personAvaliableFeatures,
        getExtrContactFromPboList,
        getOrSetExtrContactByPhoneNumber,
      },
    } = this.props;

    let personPbo: IPromiseBasedObservable<AxiosResponseT<PersonModel>> = null;
    let rootParticipant: ParticipantModel = null;
    const isOneOnOne =
      from === 'group-info' ? true : conversation?.grouping === 'OneOnOne';
    const grouping =
      from === 'group-info' ? 'OneOnOne' : conversation?.grouping;
    const conferenceIsActive =
      !isNullOrUndefined(conversation?.activeConference) &&
      conversation?.activeConference.sessionId !== '';
    const userIsInActiveVideoConference = !isNullOrUndefined(
      loggedInUserActiveConferenceConversation
    );

    const isCurrentConversation =
      getCurrentConversationId() === conversation?.id;

    const isVideoCallDisabled =
      !!loggedInUserActiveConferenceConversation ||
      !personAvaliableFeatures.video.enabled;

    return (
      <List.Item
        as={NavLink}
        to={
          conversation?.id
            ? `/chat/conversations/${conversation?.id}/${linkSuffix || 'chat'}`
            : '/chat'
        }
        className={cx('list-item-ih flex-row showhover', {
          active: isCurrentConversation,
          hovered: this.hovered || from === 'group-info',
        })}
        onMouseOver={this.onMouseOver}
        onMouseLeave={this.onMouseLeave}
        id={conversation?.id}
        key={conversationId}
        onClick={this.clearScrollToMessageId}
      >
        <List.Content className="list-item-ih-image flex-zero">
          {/* IMPORTANT: Checks against `participantsPbo` must be against `null` (see ParticipantStore.selectConversatinParticipants) RP 2018-12-12 */}
          {participantsPbo !== null &&
            participantsPbo.case({
              pending: () => (
                <LazyLoader size="tiny" />
              ),
              rejected: (reason) =>
                reason.message === 'Network Error' ? null : (
                  <Message
                    visible
                    error
                    content={
                      'Error loading Participant: ' +
                      get(reason, 'response.data.message', 'No Message')
                    }
                  />
                ),
              fulfilled: (
                ptcResp: AxiosResponseT<ResultsCollection<ParticipantModel>>
              ) => {
                const ptcDataResults: ParticipantModel[] = get(
                  ptcResp,
                  'data.results',
                  []
                );
                const otherPerson = ptcDataResults.find(
                  (p) =>
                    get(p, 'personId', 0) > 0 &&
                    get(p, 'personId', 0) !== loggedInPersonId
                );
                const otherPersonPhoneNumber = ptcDataResults.find(
                  (p) => !isNullOrUndefined(p.phone)
                );
                const mySelf = ptcDataResults.find(
                  (p) => get(p, 'personId', 0) === loggedInPersonId
                );
                const phoneNumNoPlus = isEmpty(otherPersonPhoneNumber?.phone)
                  ? null
                  : formatNumberNoPlusIfUS(otherPersonPhoneNumber.phone);

                if (
                  !isNullOrUndefined(otherPerson) &&
                  isNullOrUndefined(otherPersonPhoneNumber)
                ) {
                  const opId = get(otherPerson, 'personId', 0);
                  personPbo = selectParticipantPersonInfo(opId);
                  return (
                    <ActivityItemPresenceDisplayObserver
                      conversationGrouping={grouping}
                      conversationId={conversation?.id}
                      personId={opId}
                      phoneNumber={phoneNumNoPlus}
                      personPbo={personPbo}
                      selectPersonPresenceStatus={selectPersonPresenceStatus}
                      selectUnreadCounts={selectUnreadCounts}
                      setConversationAndTotalUnreadCount={
                        setConversationAndTotalUnreadCount
                      }
                      showUnread={true}
                      updateMyLastReadMessage={updateMyLastReadMessage}
                      getExtrContactByPhoneNumber={getExtrContactByPhoneNumber}
                    />
                  );
                } else if (
                  mySelf !== undefined &&
                  otherPerson === undefined &&
                  isNullOrUndefined(otherPersonPhoneNumber)
                ) {
                  // nobody but yourself (so lonely)
                  personPbo = selectParticipantPersonInfo(mySelf.personId);
                  return (
                    <ActivityItemPresenceDisplayObserver
                      conversationGrouping={grouping}
                      conversationId={conversation?.id}
                      personId={loggedInPersonId}
                      personPbo={personPbo}
                      selectPersonPresenceStatus={selectPersonPresenceStatus}
                      selectUnreadCounts={selectUnreadCounts}
                      phoneNumber={phoneNumNoPlus}
                      setConversationAndTotalUnreadCount={
                        setConversationAndTotalUnreadCount
                      }
                      showUnread={true}
                      updateMyLastReadMessage={updateMyLastReadMessage}
                      getExtrContactByPhoneNumber={getExtrContactByPhoneNumber}
                    />
                  );
                } else {
                  // external/unknown users
                  return (
                    <ActivityItemPresenceDisplayObserver
                      conversationGrouping={grouping}
                      conversationId={conversation?.id}
                      phoneNumber={phoneNumNoPlus}
                      personId={get(otherPerson, 'personId', 0)}
                      personPbo={personPbo}
                      selectPersonPresenceStatus={selectPersonPresenceStatus}
                      selectUnreadCounts={selectUnreadCounts}
                      setConversationAndTotalUnreadCount={
                        setConversationAndTotalUnreadCount
                      }
                      showUnread={true}
                      updateMyLastReadMessage={updateMyLastReadMessage}
                      getExtrContactByPhoneNumber={getExtrContactByPhoneNumber}
                    />
                  );
                }
              },
            })}
        </List.Content>
        <List.Content className="list-item-ih-content flex-grow-shrink">
          {participantsPbo?.case({
            pending: () => <LazyLoader size="tiny" />,
            rejected: (reason) =>
              reason.message === 'Network Error' ? null : (
                <Message
                  visible
                  error
                  content={
                    'Error loading Participant: ' +
                    get(reason, 'response.data.message', 'No Message')
                  }
                />
              ),
            fulfilled: (
              ptcResp: AxiosResponseT<ResultsCollection<ParticipantModel>>
            ) => {
              const ptcDataResults: ParticipantModel[] = get(
                ptcResp,
                'data.results',
                []
              );
              return (
                <ChatListItemTextRenderer
                  {...this.props}
                  conversationId={conversation?.id}
                  participants={ptcDataResults}
                  addProperName={conversationStore.addProperName}
                  ui={ui}
                  getExtrContactFromPboList={getExtrContactFromPboList}
                  getExtrContactByPhoneNumber={getExtrContactByPhoneNumber}
                  getOrSetExtrContactByPhoneNumber={
                    getOrSetExtrContactByPhoneNumber
                  }
                  from={from}
                />
              );
            },
          })}
        </List.Content>

        <List.Content
          className={cx('list-item-ih-hover-buttons flex-shrink', {
            active: isCurrentConversation,
            hovered: this.hovered || from === 'group-info',
          })}
        >
          {isOneOnOne && participantsPbo !== null && (
            <ChatDetailsButton
              compact
              onClick={this.detailsClick}
              {...{
                size: buttonSize,
                popupPosition: 'bottom right',
                testid,
              }}
            />
          )}
          {isOneOnOne &&
            participantsPbo !== null &&
            participantsPbo.case({
              pending: () => <LazyLoader size="tiny" />,
              rejected: (reason) =>
                reason.message === 'Network Error' ? null : (
                  <Message
                    visible
                    error
                    content={
                      'Error loading Participant: ' +
                      get(reason, 'response.data.message', 'No Message')
                    }
                  />
                ),
              fulfilled: (
                ptcResp: AxiosResponseT<ResultsCollection<ParticipantModel>>
              ) => {
                const ptcDataResults: ParticipantModel[] = get(
                  ptcResp,
                  'data.results',
                  []
                );
                const otherPtcs = ptcDataResults.filter(
                  (p) => get(p, 'personId', 0) !== loggedInPersonId
                );
                if (otherPtcs.length > 0) {
                  rootParticipant = otherPtcs.find(
                    (p) => p.personId === currentPersonId
                  );
                  if (
                    rootParticipant !== undefined &&
                    rootParticipant !== null
                  ) {
                    if (get(rootParticipant, 'personId', 0) !== 0) {
                      personPbo = selectParticipantPersonInfo(
                        rootParticipant.personId
                      );
                      if (personPbo !== undefined) {
                        return (
                          <ActivityItemPhoneVideoButtons
                            compact
                            {...{ buttonSize }}
                            isSMSConversation={conversation?.IsSmsConversation}
                            activeConference={conversation?.activeConference}
                            conversationId={conversation?.id}
                            grouping={grouping}
                            loggedInUserActiveConferenceConversation={
                              loggedInUserActiveConferenceConversation
                            }
                            makeCall={makeCall}
                            personPbo={personPbo}
                            phoneCalls={phoneCall.phoneCalls}
                            postConferenceByConversationId={
                              postConferenceByConversationId
                            }
                            uiStore={ui}
                            listOfCMutedConverstions={toJS(
                              ui.listOfMutedConversation
                            )}
                            currentPersonId={currentPersonId?.toString()}
                            loadOrCreateConversationWithPost={
                              conversationStore.loadOrCreateConversationWithPost
                            }
                            toggleMuteStatus={this.toggleMuteStatus}
                            selectIfConvMuted={ui.selectIfConvMuted}
                            from={from}
                            setChannelInfoDetails={
                              conversationStore.setChannelInfoDetails
                            }
                            setShowPersonDetails={setShowPersonDetails}
                            getExtrContactByPhoneNumber={
                              getExtrContactByPhoneNumber
                            }
                            loggedInPersonVideoFeature={
                              personAvaliableFeatures.video
                            }
                          />
                        );
                      }
                    } else {
                      return (
                        <ActivityItemPhoneVideoButtons
                          isSMSConversation={conversation?.IsSmsConversation}
                          activeConference={conversation?.activeConference}
                          conversationId={conversation?.id}
                          {...{
                            buttonSize,
                            grouping,
                            loggedInAccountId,
                            loggedInUserActiveConferenceConversation,
                            makeCall,
                            postConferenceByConversationId,
                            isOnline,
                            from,
                            setShowPersonDetails,
                            getExtrContactByPhoneNumber,
                          }}
                          phone={rootParticipant.phone}
                          phoneCalls={phoneCall.phoneCalls}
                          uiStore={ui}
                          listOfCMutedConverstions={toJS(
                            ui.listOfMutedConversation
                          )}
                          currentPersonId={currentPersonId?.toString()}
                          loadOrCreateConversationWithPost={
                            conversationStore.loadOrCreateConversationWithPost
                          }
                          toggleMuteStatus={this.toggleMuteStatus}
                          selectIfConvMuted={ui.selectIfConvMuted}
                          setChannelInfoDetails={
                            conversationStore.setChannelInfoDetails
                          }
                          loggedInPersonVideoFeature={
                            personAvaliableFeatures.video
                          }
                        />
                      );
                    }
                  }
                }
                return <span />;
              },
            })}
          {!isOneOnOne && (
            <>
              <ChatDetailsButton
                compact
                isGroupChat
                onClick={this.detailsClick}
                {...{
                  size: buttonSize,
                  popupPosition: 'bottom right',
                  testid,
                }}
              />
              {config &&
                !isVideoCallDisabled &&
                !conversation?.IsSmsConversation && (
                  <IconButton
                    compact
                    onClick={this.placeVideoCallConference}
                    content={selectProperVideoTooltipMessageForGroups(
                      conferenceIsActive,
                      userIsInActiveVideoConference
                    )}
                    disabled={{
                      title: 'Video conference unavailable',
                      condition:
                        userIsInActiveVideoConference ||
                        !personAvaliableFeatures.video.enabled,
                    }}
                    icon="video"
                    testid={`${testid}-buttonStartVideoCall`}
                    {...{ popupPosition: 'bottom right', size: buttonSize }}
                  />
                )}
            </>
          )}
          {from !== 'group-info' && (
            <>
              <Popup
                key="activity-list-item"
                basic
                on="click"
                position="right center"
                className="no-padding more-action-buttons-wrapper"
                trigger={
                  <Button
                    basic
                    compact
                    onClick={this.openMoreOptions(conversationId)}
                    className="zero-right-pm"
                  >
                    <img className="small-icon" src={dotsIcon} />
                  </Button>
                }
                content={
                  ui.showMoreActionOptions.conversationId ===
                    conversationId && (
                    <MoreActivityButton
                      activeConference={conversation?.activeConference}
                      conversationId={conversation?.id}
                      loggedInUserActiveConferenceConversation={
                        loggedInUserActiveConferenceConversation
                      }
                      postConferenceByConversationId={
                        postConferenceByConversationId
                      }
                      uiStore={ui}
                      currentPersonId={currentPersonId?.toString()}
                      loadOrCreateConversationWithPost={
                        conversationStore.loadOrCreateConversationWithPost
                      }
                      toggleMuteStatus={this.toggleMuteStatus}
                      showMoreActionOptions={
                        ui.showMoreActionOptions.conversationId ===
                        conversationId
                      }
                      conversation={conversation}
                      conversationStore={conversationStore}
                      config={config}
                      personPbo={personPbo}
                      setChannelInfoDetails={
                        conversationStore.setChannelInfoDetails
                      }
                      setShowPersonDetails={setShowPersonDetails}
                      toggleConfirmationModal={this.toggleConfirmationModal}
                      from={from}
                      loggedInPersonVideoFeature={personAvaliableFeatures.video}
                      participantStore={participant}
                    />
                  )
                }
              />
              <Modal
                size="mini"
                id="confirmation-modal"
                open={this.state.showConfirmationModal}
                dimmer="blurring"
                closeOnDimmerClick={true}
                centered={true}
                onClose={this.toggleConfirmationModal}
                className="confirmation-modal"
              >
                <Modal.Content>
                  <div className="text-center margin-top-1rem">
                    Chat will be removed from your <b>{'Recent History'}</b>{' '}
                    list. Are you sure you want to continue?
                  </div>
                  <div className="buttons flex-row flex-align-items-center flex-justify-space-around margin-top-2rem">
                    <Button
                      primary
                      onClick={this.handleArchiveConversation(conversationId)}
                      type="submit"
                      ref={this.button}
                    >
                      Confirm
                    </Button>
                    <Button secondary onClick={this.toggleConfirmationModal}>
                      Cancel
                    </Button>
                  </div>
                </Modal.Content>
              </Modal>
            </>
          )}
        </List.Content>
      </List.Item>
    );
  }
}
export default inject(
  STORE_CONFIG,
  STORE_PHONE_CALL,
  STORE_UI,
  STORE_PARTICIPANT
)(withRouter(observer(ActivityListItem)));
