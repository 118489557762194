import {
  RTCStats,
  RTCInboundRtpStreamStats,
  RTCOutboundRtpStreamStats,
  RTCIceCandidatePairStats,
} from '../index.types';

/**
 * Type guard to check if a report is an inbound audio report
 */
export const isInboundAudioReport = (
  report: RTCStats
): report is RTCInboundRtpStreamStats => {
  return (
    report.type === 'inbound-rtp' &&
    (report as RTCInboundRtpStreamStats).kind === 'audio'
  );
};

/**
 * Type guard to check if a report is an outbound audio report
 */
export const isOutboundAudioReport = (
  report: RTCStats
): report is RTCOutboundRtpStreamStats => {
  return (
    report.type === 'outbound-rtp' &&
    (report as RTCOutboundRtpStreamStats).kind === 'audio'
  );
};

/**
 * Type guard to check if a report is a candidate pair report
 */
export const isCandidatePairReport = (
  report: RTCStats
): report is RTCIceCandidatePairStats => {
  return (
    report.type === 'candidate-pair' &&
    (report as RTCIceCandidatePairStats).state === 'succeeded'
  );
};

/**
 * Type guard to check if an object is a codec report
 */
export const isCodecReport = (report: RTCStats): report is RTCStats & { mimeType: string } => {
  return report.type === "codec";
};