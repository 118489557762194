import localforage from 'localforage';
import { CollectedStats } from '../index.types';
import { ALERTS_THRESHOLDS, STORAGE_KEY } from './index.constants';
import { VoipAlert, VoipMonitorType } from './index.types';

/**
 * VoipAlertAdapter is a class that saves and retrieves voip alerts from local storage
 * @description This is an exploratory implementation that can be used to save alerts in the future
 */
export class VoipAlertAdapter {
  /**
   * Save the alert
   * @param alert - The alert
   */
  private static async saveAlert(alert: VoipAlert): Promise<void> {
    const existingAlerts = (await this.getAlerts()) || [];
    existingAlerts.push(alert);
    await localforage.setItem(STORAGE_KEY, existingAlerts);
  }

  /**
   * Get the alerts
   * @returns The alerts
   */
  public static async getAlerts(): Promise<VoipAlert[]> {
    return (await localforage.getItem(STORAGE_KEY)) || [];
  }

  /**
   * Check the stats and save alerts if necessary
   * @param personId - The person id
   * @param callId - The call id
   * @param stats - The stats
   */
  public static async checkStats(
    personId: string,
    callId: string,
    stats: CollectedStats
  ): Promise<void> {
    const { inboundAudio, candidatePair, time } = stats;

    const baseAlertData = {
      personId,
      callId,
      timestamp: time
    };

    if (inboundAudio) {
      /**
       * PACKET LOSS
       * Rule:More than 3% is problematic
       */
      const packetLossPercent =
      (inboundAudio.packetsLost / inboundAudio.packetsReceived) * 100;
      // Check packet loss
      if (packetLossPercent > ALERTS_THRESHOLDS.PACKET_LOSS_THRESHOLD) {
        await this.saveAlert({
          ...baseAlertData,
          type: VoipMonitorType.HIGH_PACKET_LOSS,
          value: packetLossPercent,
          threshold: ALERTS_THRESHOLDS.PACKET_LOSS_THRESHOLD,
        });
      }

      /**
       * JITTER
       * Rule:More than 30ms is problematic
       */
      const jitterMs = inboundAudio.jitter * 1000;
      // Check jitter
      if (jitterMs > ALERTS_THRESHOLDS.JITTER_THRESHOLD) {
        await this.saveAlert({
          ...baseAlertData,
          type: VoipMonitorType.HIGH_JITTER,
          value: jitterMs,
          threshold: ALERTS_THRESHOLDS.JITTER_THRESHOLD,
        });
      }
    }

    /**
     * LATENCY
     * Rule: More than 150ms is problematic
     */
    if (candidatePair) {
      const roundTripTimeMs = candidatePair.currentRoundTripTime * 1000;
      // Check round-trip time
      if (roundTripTimeMs > ALERTS_THRESHOLDS.LATENCY_THRESHOLD) {
        await this.saveAlert({
          ...baseAlertData,
          type: VoipMonitorType.HIGH_LATENCY,
          value: roundTripTimeMs,
          threshold: ALERTS_THRESHOLDS.LATENCY_THRESHOLD,
        });
      }
    }
  }

  /**
   * Clear the alerts
   */
  public static async clearAlerts(): Promise<void> {
    await localforage.removeItem(STORAGE_KEY);
  }
}
