import { NotificationStackPosition } from '../NotificationStack/index.types';
import { NotificationConfig } from './index.types';

export const NOTIFICATION_CONFIG: NotificationConfig = {
  DEFAULT_GAP: 8,
  DEFAULT_LIMIT: 3,
  HOVER_TIMEOUT: 50,
  NOTIFICATION_WIDTH: 400,
  SCREEN_PADDING: 16,
  STACK_OFFSET: 8,
  STACK_SCALE: 0.95,
};

export const POSITIONS = [
  'tl',
  'tc',
  'tr',
  'bl',
  'bc',
  'br',
] satisfies NotificationStackPosition[];
