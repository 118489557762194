import { Session, SessionDescriptionHandler } from 'sip.js';

export enum VoipMonitorEvent {
  MONITORING_STARTED = 'monitoringStarted',
  MONITORING_ENDED = 'monitoringEnded',
  ERROR = 'error',
}

/**
 * Options for WebRTC stats collection
 */
export interface WebRTCStatsOptions {
  getStatsInterval?: number;
  maxMetricsPerCall?: number;
  sendFullCallStats?: boolean;
  enableAlertAdapter?: boolean;
}

/**
 * Structure for collected statistics
 */
export interface CollectedStats {
  time: string;
  inboundAudio?: RTCInboundRtpStreamStats;
  outboundAudio?: RTCOutboundRtpStreamStats;
  inboundVideo?: RTCInboundRtpStreamStats;
  outboundVideo?: RTCOutboundRtpStreamStats;
  candidatePair?: RTCIceCandidatePairStats;
  raw: RTCStatsReportType[];
}

export interface CodecInfo {
  codecId: string;
  mimeType: string;
}

/**
 * Structure for all call statistics
 */
export interface CallStats {
  metadata: {
    personId: string;
    callId: string;
    startTime?: string;
    endTime: string;
    duration: number;
    codec?: CodecInfo;
  };
  summary: {
    mosScore: number;
    averageJitter: number;
    totalPacketsLost: number;
    averageRoundTripTime: number;
    availableBitrate: number;
  };
  stats?: CollectedStats[];
}

interface ExtendedSessionDescriptionHandler extends SessionDescriptionHandler {
  peerConnection: RTCPeerConnection;
}

export interface ExtendedSession extends Session {
  sessionDescriptionHandler: ExtendedSessionDescriptionHandler;
}

/**
 * RTCStats is the base for all types of RTC stats reports
 */
export interface RTCStats {
  timestamp: number;
  type: RTCStatsType;
  id: string;
}

/**
 * RTCStatsType is the type of RTC stats reports
 */
type RTCStatsType =
  | 'codec'
  | 'inbound-rtp'
  | 'outbound-rtp'
  | 'remote-inbound-rtp'
  | 'remote-outbound-rtp'
  | 'media-source'
  | 'media-playout'
  | 'peer-connection'
  | 'data-channel'
  | 'transport'
  | 'candidate-pair'
  | 'local-candidate'
  | 'remote-candidate'
  | 'certificate';

/**
 * RTCInboundRtpStreamStats is the type of RTC stats reports for inbound RTP streams (audio/video received)
 */
export interface RTCInboundRtpStreamStats extends RTCStats {
  type: 'inbound-rtp';
  kind: 'audio' | 'video';
  ssrc: number;
  packetsReceived?: number;
  packetsLost?: number;
  jitter?: number;
  framesDecoded?: number;
  audioLevel?: number;
  totalAudioEnergy?: number;
}

/**
 * RTCOutboundRtpStreamStats is the type of RTC stats reports for outbound RTP streams (audio/video sent)
 */
export interface RTCOutboundRtpStreamStats extends RTCStats {
  type: 'outbound-rtp';
  kind: 'audio' | 'video';
  ssrc: number;
  packetsSent?: number;
  bytesSent?: number;
  framesSent?: number;
  framesEncoded?: number;
  codecId?: string;
  mimeType?: string;
}

/**
 * RTCIceCandidatePairStats is the type of RTC stats reports for ICE candidate pairs (network connection)
 */
export interface RTCIceCandidatePairStats extends RTCStats {
  type: 'candidate-pair';
  state: RTCStatsIceCandidatePairState;
  currentRoundTripTime?: number;
  bytesReceived?: number;
  bytesSent?: number;
  availableOutgoingBitrate?: number;
  localCandidateId?: string;
  remoteCandidateId?: string;
}

/**
 * Possible states of an ICE candidate pair
 */
type RTCStatsIceCandidatePairState =
  | 'frozen'
  | 'waiting'
  | 'in-progress'
  | 'failed'
  | 'succeeded';

/**
 * RTCStatsReportType is the type of RTC stats reports for use in collectRTCMetrics
 */
export type RTCStatsReportType =
  | RTCInboundRtpStreamStats
  | RTCOutboundRtpStreamStats
  | RTCIceCandidatePairStats
  | RTCStats;
