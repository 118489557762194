import { CollectedStats } from '../index.types';

/**
 * Calculates statistics (mean and variance) from an array of numeric values
 * @param values - Array of numeric values
 * @returns Object containing mean and variance
 */
export const calculateStats = (values: number[]) => {
  if (values.length === 0) return { mean: 0, variance: 0 };

  const sum = values.reduce((acc, val) => acc + val, 0);
  const mean = sum / values.length;
  const variance =
    values.reduce((acc, val) => acc + Math.pow(val - mean, 2), 0) / values.length;

  return { mean, variance };
};

/**
 * Extracts audio and network metrics from collected WebRTC stats
 * @param metrics - Array of collected WebRTC stats
 * @returns Object containing arrays of jitter, packet loss, and RTT values
 */
export const extractAudioMetrics = (metrics: CollectedStats[]) => {
  const jitterValues: number[] = [];
  const packetLossValues: number[] = [];
  const rttValues: number[] = [];

  metrics.forEach((stat) => {
    if (stat.inboundAudio) {
      jitterValues.push(stat.inboundAudio.jitter || 0);
      packetLossValues.push(stat.inboundAudio.packetsLost || 0);
    }

    if (stat.candidatePair) {
      rttValues.push(
        stat.candidatePair.currentRoundTripTime
          ? stat.candidatePair.currentRoundTripTime * 1000
          : 0
      ); // Convert to ms
    }
  });

  return { jitterValues, packetLossValues, rttValues };
};

/**
 * Calculates R-value (transmission rating factor) based on ITU-T G.107 E-Model
 * @see ITU-T G.107: https://www.itu.int/rec/T-REC-G.107/en
 *
 * ## 📌 ITU-T G.107 E-Model Enhancements for MOS Calculation
 *
 * | **Improvement**                     | **Reference**                                         | **Description** |
 * |--------------------------------------|------------------------------------------------------|----------------|
 * | **RTT Impairment Factor (Id)**       | [ITU-T G.107](https://www.itu.int/rec/T-REC-G.107/en) | Adjusts penalty based on excessive RTT, reducing over-penalization. |
 * | **Packet Loss Impairment (Ie-eff)**  | [ITU-T G.113](https://www.itu.int/rec/T-REC-G.113/en) | Uses logarithmic model with an upper bound for better packet loss penalty calculation. |
 * | **Jitter-based Penalty (Ij)**        | [ITU-T G.108](https://www.itu.int/rec/T-REC-G.108/en) | Takes jitter **variance** into account, better handling network spikes. |
 * | **ITU-T G.107 MOS Mapping**         | [ITU-T G.107](https://www.itu.int/rec/T-REC-G.107/en) | Improved MOS calculation formula ensures better differentiation of quality levels. |
 *
 * @param jitterStats - Jitter statistics object with mean and variance
 * @param packetLossStats - Packet loss statistics object with mean and variance
 * @param rttStats - Round-trip time statistics object with mean and variance
 * @returns R-value for audio quality
 */
export const calculateRValue = (
  jitterStats: { mean: number; variance: number },
  packetLossStats: { mean: number; variance: number },
  rttStats: { mean: number; variance: number }
) => {
  const BASE_R_VALUE = 93.2; // Default R0 value for wideband speech

  // RTT impairment factor (Id)
  // https://www.voiptroubleshooter.com/diagnosis/emodel.html
  const RTT_FACTOR = 0.024;
  const RTT_THRESHOLD = 177.3;
  const RTT_EXCESS_FACTOR = 0.11;
  const Id = RTT_FACTOR * rttStats.mean +
             RTT_EXCESS_FACTOR * (rttStats.mean - RTT_THRESHOLD) * (rttStats.mean > RTT_THRESHOLD ? 1 : 0);

  // Improved Packet Loss Impairment (Ie-eff)
  // ITU-T G.113 Packet Loss Effect: https://www.itu.int/rec/T-REC-G.113/en
  const PACKET_LOSS_FACTOR = 30;
  const PACKET_LOSS_MULTIPLIER = 15;
  const Ie_eff = PACKET_LOSS_FACTOR * Math.log(1 + (PACKET_LOSS_MULTIPLIER * Math.min(packetLossStats.mean, 99.9)) / 100);

  // Jitter-based penalty (Ij) with variance consideration
  // ITU-T G.108: https://www.itu.int/rec/T-REC-G.108/en
  const JITTER_FACTOR = 0.2;
  const Ij = JITTER_FACTOR * jitterStats.mean + 0.1 * Math.sqrt(jitterStats.variance); // Extra weight for jitter spikes

  // Compute final R-Value with all impairment factors
  const R = BASE_R_VALUE - Id - Ie_eff - Ij;

  // Prevent R-value from going below zero
  return Math.max(0, R);
};

/**
 * Converts R-value to MOS (Mean Opinion Score) using ITU-T G.107 mapping
 * @see ITU-T G.107: https://www.itu.int/rec/T-REC-G.107/en
 * @param rValue - R-value (transmission rating factor)
 * @returns MOS score between 1.0 and 5.0
 */
export const rValueToMos = (rValue: number): number => {
  if (rValue <= 0) {
    return 1.0;
  }

  if (rValue > 100) {  // Handle very high R-values
    return 5.0;
  }

  // Formula to convert R-value to MOS
  // MOS = 1 + 0.035*R + 7*10^-6*R*(R-60)*(100-R)
  return rValue > 0
    ? 1 + 0.035 * rValue + 7 * 0.000001 * rValue * (rValue - 60) * (100 - rValue)
    : 1.0;
};

/**
 * Calculates MOS score based on collected metrics using the ITU-T G.107 E-Model
 * @see ITU-T G.107: https://www.itu.int/rec/T-REC-G.107/en
 * @param metrics - Array of collected stats
 * @returns MOS score between 1.0 and 5.0
 */
export const calculateMosScore = (metrics: CollectedStats[]): number => {
  if (metrics.length === 0) return 1;

  const { jitterValues, packetLossValues, rttValues } = extractAudioMetrics(metrics);

  const jitterStats = calculateStats(jitterValues);
  const packetLossStats = calculateStats(packetLossValues);
  const rttStats = calculateStats(rttValues);

  const rValue = calculateRValue(jitterStats, packetLossStats, rttStats);

  return parseFloat(rValueToMos(rValue).toFixed(2));
};
