import { useQuery } from '@tanstack/react-query';
import { API_ENDPOINTS, enableVoicemailBadgeCounter } from 'Constants/env';
import { VoicemailBadgeCounter } from 'Interfaces/voicemails';
import API from '~/api';

export const QUERY_KEYS = {
  voicemails: ['voicemails/count'],
};

export const useBadgeCounters = () => {
  const voicemailQuery = useQuery<VoicemailBadgeCounter>({
    queryKey: QUERY_KEYS.voicemails,
    queryFn: async () => {
      const response = await API.get<VoicemailBadgeCounter>(
        API_ENDPOINTS.UnreadVoicemailCount
      );
      return response?.data;
    },
    enabled: enableVoicemailBadgeCounter,
  });

  return {
    voicemailQuery,
  };
};
