import type { ActivityListItemsProps } from './interfaces';
import LazyLoader from 'Components/LazyLoader';
import HistoryListContainer from 'Containers/HistoryList';
import { IUnreadMessageAndMentionCountsByConversationId } from 'Interfaces/components';
import { get, isEmpty } from 'lodash';
import { runInAction } from 'mobx';
import { observer } from 'mobx-react';
import * as React from 'react';
import { Grid, List, Message } from 'semantic-ui-react';
import { isNullOrUndefined } from 'util';
import withRouter from '../../hocs/WithRouter';
import { ParticipantModel } from '../../models';
import { formatNumberNoPlusIfUS } from '../../utils/phoneUtil';
import ActivityListItem from '../ActivityListItem';

@observer
export class ActivityListItemsListRenderer extends React.Component<
  ActivityListItemsProps,
  {}
> {
  get UnreadCounts() {
    const { conversationStore, selectUnreadCounts } = this.props;
    const convList = conversationStore.RecentConversations;

    return convList.reduce<IUnreadMessageAndMentionCountsByConversationId>(
      (prev, next) => {
        prev[next.id] = selectUnreadCounts(next.id);
        return prev;
      },
      {}
    );
  }

  render() {
    const {
      conversationStore,
      currentConversationId,
      listUnreadFirst,
      loadPresenceIfMissing,
      loggedInAccountId,
      loggedInPersonId,
      makeCall,
      phoneStore,
      selectConversationParticipants,
      selectParticipantPersonInfo,
      selectPersonPresenceStatus,
      selectUnreadCounts,
      setConversationAndTotalUnreadCount,
      updateMyLastReadMessage,
      updateRoute,
      personStore,
      setShowPersonDetails,
      contact: { getContactByPhoneNumber },
    } = this.props;
    const convList = conversationStore.RecentConversations;

    const phoneNumbersList = [];

    convList.forEach((conversation) => {
      if (conversation.grouping === 'Group') {
        conversation.participants?.forEach(({ personId, phone }) => {
          if (
            conversationStore.isParsable(personId) &&
            personId !== 0 &&
            personId !== personStore.loggedInPersonId
          ) {
            const personPbo =
              personStore.loadPersonByIdGetIfMissingGet(personId);
            return personPbo?.case({
              fulfilled: (resp) => {
                return resp.data;
              },
            });
          } else if (phone) {
            phoneNumbersList.push(phone);
          }
        });
        return;
      }

      const participantsPbo = selectConversationParticipants(conversation.id);

      participantsPbo !== null &&
        participantsPbo.case({
          fulfilled: (ptcResp) => {
            const ptcDataResults: ParticipantModel[] = get(
              ptcResp,
              'data.results',
              []
            );
            const otherPerson = ptcDataResults.find(
              (p) =>
                get(p, 'personId', 0) > 0 &&
                get(p, 'personId', 0) !== loggedInPersonId
            );
            const otherPersonPhoneNumber = ptcDataResults.find(
              (p) => !isNullOrUndefined(p.phone)
            );
            const mySelf = ptcDataResults.find(
              (p) => get(p, 'personId', 0) === loggedInPersonId
            );
            const phoneNumNoPlus = isEmpty(otherPersonPhoneNumber?.phone)
              ? null
              : formatNumberNoPlusIfUS(otherPersonPhoneNumber.phone);

            if (
              (isNullOrUndefined(otherPerson) ||
                !isNullOrUndefined(otherPersonPhoneNumber)) &&
              (mySelf === undefined ||
                otherPerson !== undefined ||
                !isNullOrUndefined(otherPersonPhoneNumber))
            ) {
              const contactByPhone = getContactByPhoneNumber(phoneNumNoPlus);

              contactByPhone &&
                phoneNumbersList.push(contactByPhone.data.phoneNumber);
            }
          },
        });
    });

    personStore.getExtrContactByPhoneNumbers(phoneNumbersList);

    convList.forEach((conversation) => {
      if (conversation.grouping === 'Group') {
        runInAction(() => {
          conversation.topic = conversationStore.addProperName(
            conversation.participants
          );
        });
      }
    });

    return (
      <Grid.Column>
        <List inverted verticalAlign={'middle'} size="large" selection>
          <div className="items recent recent-mediaq margin-0">
            {convList
              .slice()
              .sort((a, b) => {
                const aDate = a.lastMessageDate || a.created;
                const bDate = b.lastMessageDate || b.created;
                let sortResult = aDate <= bDate ? 1 : -1; // 1 is B before A, -1 is A before B
                if (listUnreadFirst) {
                  const unreadCountsA = this.UnreadCounts[a.id];
                  const unreadCountsB = this.UnreadCounts[b.id];
                  const aHasUnreads =
                    !isEmpty(unreadCountsA) && unreadCountsA.unreadMessages > 0;
                  const bHasUnreads =
                    !isEmpty(unreadCountsB) && unreadCountsB.unreadMessages > 0;
                  if (aHasUnreads && !bHasUnreads) {
                    sortResult = -1; // A before B
                  } else if (!aHasUnreads && bHasUnreads) {
                    sortResult = 1; // B before A
                  } // ... Otherwise, leave the sort result alone
                }

                return sortResult;
              })
              .map((conv) => {
                return (
                  <ActivityListItem
                    from="recentFevList"
                    setShowPersonDetails={setShowPersonDetails}
                    conversation={conv}
                    conversationId={conv.id}
                    isCurrentConversation={currentConversationId === conv.id}
                    key={conv.id}
                    loadPresenceIfMissing={loadPresenceIfMissing}
                    loggedInAccountId={loggedInAccountId}
                    loggedInPersonId={loggedInPersonId}
                    loggedInUserActiveConferenceConversation={
                      conversationStore.LoggedInUserActiveConferenceConversation
                    }
                    makeCall={makeCall}
                    participantsPbo={selectConversationParticipants(conv.id)}
                    phoneCall={phoneStore}
                    postConferenceByConversationId={
                      conversationStore.postConferenceByConversationId
                    }
                    selectParticipantPersonInfo={selectParticipantPersonInfo}
                    selectPersonPresenceStatus={selectPersonPresenceStatus}
                    selectUnreadCounts={selectUnreadCounts}
                    setConversationAndTotalUnreadCount={
                      setConversationAndTotalUnreadCount
                    }
                    updateMyLastReadMessage={updateMyLastReadMessage}
                    updateRoute={updateRoute}
                    participant={this.props.participantStore}
                    isOnline={this.props.isOnline}
                    personStore={personStore}
                    conversationStore={conversationStore}
                  />
                );
              })}
            {conversationStore.RecentConversations.length === 0 && (
              <div className="empty">
                {'You do not currently have any Recent Conversations.'}
              </div>
            )}
          </div>
        </List>
      </Grid.Column>
    );
  }
}

/**
 * **This named class is not decorated with `@observer`.** Use the default export from this module if you need the decorated class.
 *
 * @export
 * @class ActivityListItems
 */
export class ActivityListItems extends React.Component<
  ActivityListItemsProps,
  {}
> {
  render() {
    const {
      phoneStore,
      activeItem,
      conversationStore,
      isOnline,
      setMoreActionOptions,
      contact,
    } = this.props;
    const isDirectory = location.pathname === '/directory';
    return (
      <Grid.Row columns={1} key="aci-row2">
        <Grid.Column>
          {!isDirectory && activeItem === 'history' && <HistoryListContainer />}
        </Grid.Column>
        {activeItem === 'recent' && (
          <Grid.Column>
            {conversationStore.loadConversationsLazyStatus &&
              conversationStore.loadConversationsLazyStatus.case({
                fulfilled: (_) => (
                  <ActivityListItemsListRenderer
                    {...this.props}
                    phoneStore={phoneStore}
                    isOnline={isOnline}
                    setMoreActionOptions={setMoreActionOptions}
                    contact={contact}
                  />
                ),
                pending: () => <LazyLoader />,
                rejected: (reason) =>
                  reason.message === 'Network Error' ? null : (
                    <Message visible error>
                      Error: {get(reason, 'response.data.message')}
                    </Message>
                  ),
              })}
          </Grid.Column>
        )}
      </Grid.Row>
    );
  }
}

export default withRouter(observer(ActivityListItems));
